@import 'var.css';
@import '../assets/fonts/iconfont.css';

*,
::before,
::after {
	padding: 0;
	margin: 0;
	box-sizing: border-box
}


body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #fff;
	
}
#root{
	/*padding-top: 1.06rem;*/
	/*padding-bottom: 1.32rem;*/
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: inherit;
	font-size: 0.24rem;
}

a {
	color: inherit;
	text-decoration: none;
}

button,
input,
textarea,
select {
	font-family: inherit;
	background: none;
	border: none;
	outline: none;
}

img {
	border: none;
	background: none;
	/* 元素宽高不确定的情况下, 自适应宽高比放置确定的宽高容器中 */
	object-fit: cover;
}

li {
	list-style: none;
}

i,
em,
b,
strong {
	font-style: inherit;
	font-weight: inherit;
}

fieldset {
	border: none;
}